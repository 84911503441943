import { GatsbySeo } from 'gatsby-plugin-next-seo'

import WarrantyClub from '../../components/ClubComponents/Warranty'

function Page() {
  return (
    <>
      <GatsbySeo title="Clube | Decathlon | Garantia " />
      <WarrantyClub />
    </>
  )
}

export default Page
