import { Image } from 'src/components/ui/Image'
import { stripUrl } from 'src/utils/stripUrl'

import { TableList } from './TableList'
import { DropDownMobile } from './DropDownMob'
import { DataMob } from './dataInfoMob'
import { ContentMob } from './ContentMob'
import './styles.scss'

const Warranty = () => {
  const findInfo = DataMob.find((item) => item.items)

  return (
    <section className="warranty_container">
      <div className="w-full flex items-center justify-center flex-col py-3 bg-[rgba(0,0,0,.4)] h-[1200px] vs:h-auto">
        <div className="w-full flex items-center justify-center">
          <Image
            className="min-w-[228px] logo"
            src={stripUrl(
              'https://decathlonstore.vteximg.com.br/arquivos/clube-dctl.png'
            )}
            alt="clube-ico"
            title="clube-ico"
            loading="lazy"
            width={270}
            height={127}
          />
        </div>
        <div className="my-[15px]">
          <h2 className="text-lg font-bold text-center tracking-[6px] text-white uppercase font-roboto">
            Mais Benefícios <br /> Mais Esporte
          </h2>
        </div>
        <div className="flex items-center justify-center rounded-[50%] w-28 h-28 bg-gradient-to-t from-[#296ffa] to-[#0073b3] mt-10">
          <Image
            src={stripUrl(
              'https://decathlonstore.vteximg.com.br/arquivos/garantia-ico.png'
            )}
            alt="garantia-ico"
            title="garantia-ico"
            loading="lazy"
            width={60}
            height={58}
          />
        </div>
        <div className="my-3 vs:max-w-[331px] w-full">
          <h1 className="text-3xl text-center uppercase font-roboto text-white font-bold">
            Garantia estendida em produtos selecionados
          </h1>
        </div>
        <div className="my-5">
          <p className="text-xl text-center font-roboto text-white">
            No Clube você tem um ano a mais de garantia em mochilas,
            equipamentos e barracas.
          </p>
        </div>
        <div className="table-desk">
          <TableList />
        </div>
      </div>
      <div className="table-mob">
        <div>
          <div>
            <div>
              {findInfo?.items?.map((item, indexItem) => {
                return (
                  <DropDownMobile btnTitle={item?.btnTitle} key={indexItem}>
                    {item.content.map((content, indexContent) => {
                      return (
                        <ContentMob
                          key={indexContent}
                          codeTitle={content.codeTitle}
                          code={content.code}
                          warrantyTitle={content.warrantyTitle}
                          warranty={content.warranty}
                          warrantyClubTitle={content.warrantyClubTitle}
                          warrantyClub={content.warrantyClub}
                        />
                      )
                    })}
                  </DropDownMobile>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Warranty
